import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import columnContainer from './columnContainer.css';

const ColumnContainer = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & { equal?: boolean }>(
  ({ children, className, equal: equal, ...props }, ref) => (
    <div className={joinClassNames(className, columnContainer({ equal: !!equal }))} {...props} ref={ref}>
      {children}
    </div>
  ),
);

ColumnContainer.displayName = 'ColumntContainer';

export default ColumnContainer;
