import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import contentColumn from './contentColumnt.css';

const ContentColumn = React.forwardRef<
  HTMLDivElement,
  React.BaseHTMLAttributes<HTMLDivElement> & { whiteBackground?: true }
>(({ whiteBackground, children, className, ...props }, ref) => (
  <div className={joinClassNames(className, contentColumn({ whiteBackground }))} {...props} ref={ref}>
    {children}
  </div>
));

ContentColumn.displayName = 'ContentColumn';

export default ContentColumn;
