import React from 'react';
import ContentColumn from './ContentColumn';
import ContentColumnHeader from './ContentColumnHeader';

const WithHeaderContentColumn = ({
  header,
  children,
  whiteBackground,
  extendedHeader,
  className,
}: {
  header: string | React.ReactNode;
  children: React.ReactNode;
  whiteBackground?: true;
  extendedHeader?: React.ReactNode;
  className?: string;
}) => (
  <div className={className}>
    <ContentColumnHeader header={header} extendedHeader={extendedHeader} />
    <ContentColumn whiteBackground={whiteBackground}>{children}</ContentColumn>
  </div>
);

export default WithHeaderContentColumn;
