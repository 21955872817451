import React from 'react';
import themeClasses from 'web/styles/themeClasses.css';
import sc from './styled';

const Line = sc.div(undefined, {
  backgroundColor: 'currentColor',
  height: 3,
  borderRadius: 2,
});

const Container = sc.div(themeClasses({ marginTop: 10 }));

const ContentColumnHeader = ({
  header,
  extendedHeader,
}: {
  header: string | React.ReactNode;
  extendedHeader?: React.ReactNode;
}) => (
  <Container>
    <div
      style={{
        display: 'flex',
        justifyContent: extendedHeader ? 'space-between' : 'flex-start',
        alignItems: 'flex-start',
        minHeight: 56,
      }}
    >
      {typeof header === 'string' ? <h3 style={{ margin: 0 }}>{header}</h3> : header}
      {extendedHeader || <></>}
    </div>
    <Line />
  </Container>
);

export default ContentColumnHeader;
